<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> Holiday List</h3>
                        </div>
                        <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;"  *ngIf="this.isAdmin">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" title="Edit" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#holidayModel">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteHoliday" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div>
                                <button type="button" title="Create" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#holidayModel" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                        
                    </div>
                    <ag-grid-angular
                    style="height: calc(100vh - 110px) !important;" 
                         class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

  <!-- Delete an item -->
  <div class="modal fade" id="deleteHoliday" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeDeleteModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteHoliday()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div> 

<!--Create an item -->
<div class="modal fade" id="holidayModel">
    <div class="modal-dialog">
      <div class="modal-content pl-5 pr-5">
        <form [formGroup]="holidayForm">
          <div class="modal-header">
            <h5 class="modal-title">Holiday List</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="input">
                <div class="input-item">
                    <input formControlName="name" type="text" required>
                    <label class="floating-label-special">Name</label>
                    <i class="ri-time-line"></i>
                </div>
            </div>
            <div class="input">
                <div class="input-item">
                    <input formControlName="holidayDate" type="date" required>
                    <label class="floating-label-special">Date</label>
                    <i class="ri-time-line"></i>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button id="closeCreateModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" data-bs-dismiss="modal" (click)="addHoliday()">Add</button>
            <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" data-bs-dismiss="modal" (click)="editHoliday()">Update</button>
        </div>
        </form>
      </div>
    </div>
  </div>

