<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-header row justify-content-between align-items-center">
                    <div class="title col-sm-6 d-flex">
                        <h3><a routerLink="profile-page" [routerLinkActive]="['active']" class="active" style="white-space: nowrap;">My Profile</a></h3>
                        <h3><a routerLink="upload-document" [routerLinkActive]="['active']" style="white-space: nowrap;">Upload Document</a></h3>
                        <h3><a routerLink="profile-setting" [routerLinkActive]="['active']" style="white-space: nowrap;">Settings</a></h3>
                        <h3><a routerLink="profile-dashboard-layout" [routerLinkActive]="['active']" style="white-space: nowrap;">Dashboard Layout</a></h3>
                    </div>
                </div>
                <div class="canvas-data">
                    <router-outlet></router-outlet>
                </div>
            </section>
        </section>
    </article>
</main>