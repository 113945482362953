<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <form [formGroup]="leaveapplicationForm">
          <div class="canvas-item">
            <div class="canvas-header row justify-content-between align-items-center">
              <div class="title col-sm-6">
                <div class="d-flex align-items-center">
                  <div class="edit">
                    <button title="back page" (click)="backToMyLeave()">
                      <span><i class="ri-arrow-left-line"></i></span>
                    </button>
                  </div>
                  <h3 class="px-3">Leave Application</h3>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                <div class="canvas-data">
                  <div class="row">
                    <div class="col-sm-12 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="applicationTitle" required />
                          <label class="floating-label">Application Title</label>
                          <i class="ri-calendar-todo-line"></i>
                        </div>
                        <div>
                          <mat-error *ngIf="f.applicationTitle.touched && f.applicationTitle.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.applicationTitle.errors.required">Application title is required</mat-error>
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <input *ngIf="!leaveapprowId" type="date" value="{{ this.TodayDate }}" disabled/>
                          <input *ngIf="leaveapprowId" type="date" formControlName="applicationDate" disabled/>
                          <label class="floating-label-special">Date</label>
                          <i class="ri-user-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item" *ngIf="!this.isHRAdmin">
                          <input list="taskOwnerDatalist" disabled formControlName="userId" placeholder="Applied For User" (change)="getEmployeeLeave($event)"/>
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label-special">Applied For</label>
                          <i class="ri-shield-user-line"></i>
                        </div>
                        <div class="input-item" *ngIf="this.isHRAdmin">
                          <input list="taskOwnerDatalist" formControlName="userId" placeholder="Applied For User" (change)="getEmployeeLeave($event)"/>
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label-special">Applied For</label>
                          <i class="ri-shield-user-line"></i>
                        </div>
                        <mat-error *ngIf="f.userId.touched && f.userId.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.userId.errors.required">Applied For User is required</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <!-- <div class="col-sm-12 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input type="text" required>
                                                    <label class="floating-label">Travel Type</label>
                                                    <i class="ri-user-line"></i>
                                                </div>
                                            </div>
                                        </div> -->
                    <div class="col-sm-12 px-3 py-1">
                      <div class="input">
                        <div class="input-item" *ngIf="this.isHRAdmin === true">
                          <select formControlName="leavetype" required>
                            <option value="" disabled selected> Select leave type</option>
                            <option value="Earned Leave" *ngIf="this.isLWP === false">Casual Leave</option>
                            <option value="Compensatory leave" *ngIf="this.isCompOffNotAvailable === false">Compensatory leave</option>
                            <option value="Paternity Leave" *ngIf="this.isPaternityLeaveType ===true">Paternity Leave</option>
                            <option value="Maternity Leave" *ngIf="this.isMaternityLeaveType === true">Maternity Leave</option>
                            <option value="Leave Without Pay" *ngIf="this.isLWP === true">Leave Without Pay</option>
                          </select>
                          <label for="leavetype" class="floating-label floating-label-special">Leave Type*</label>
                          <i class="ri-user-line"></i>
                        </div>

                        <div class="input-item" *ngIf="this.isHRAdmin === false">
                          <select formControlName="leavetype" required>
                            <option value="" disabled selected>Select leave type</option>
                            <option value="Earned Leave" *ngIf="this.isLWP === false">Casual Leave</option>
                            <option value="Compensatory leave" *ngIf="this.isCompOffNotAvailable === false">Compensatory leave</option>
                            <option value="Leave Without Pay" *ngIf="this.isLWP === true">Leave Without Pay</option>
                          </select>
                          <label for="leavetype" class="floating-label floating-label-special">Leave Type*</label>
                          <i class="ri-user-line"></i>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="row pt-3 pb-3">
                    <div class="col-sm-6">
                      <mat-slide-toggle formControlName="halfDay" (change)="getleavefromduration($event)"
                        value="true">Turn On Toggle button to apply for half day</mat-slide-toggle>
                    </div>
                    <!-- <div class="col-sm-6">
                      <mat-slide-toggle formControlName="">Turn of for unpaid leave </mat-slide-toggle>
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-sm-4 px-3 py-1">
                      <div *ngIf="! this.disableEndDate">
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="startDate" (change)="getleavefromduration($event)" max="{{this.maxDate}}" required />
                            <label class="floating-label-special">From</label>
                            <i class="ri-building-line"></i>
                          </div>
                          <mat-error *ngIf="f.startDate.touched && f.startDate.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                          </mat-error>
                        </div>
                      </div>
                    
                      <div *ngIf="this.disableEndDate">                    
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="startDate" (change)="getleavefromduration($event)" required />
                            <label class="floating-label-special">From</label>
                            <i class="ri-building-line"></i>
                          </div>
                          <mat-error *ngIf="f.startDate.touched && f.startDate.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-sm-4 px-3 py-1">
                      <div *ngIf="!this.disableEndDate">
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="endDate" (change)="getleavetoduration($event)" min="{{this.minDate}}" required />
                            <label class="floating-label-special">To</label>
                            <i class="ri-file-text-line"></i>
                          </div>
                          <mat-error *ngIf="f.endDate.touched && f.endDate.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.endDate.errors.required">End Date is required</mat-error>
                          </mat-error>
                        </div>
                      </div>

                      <div *ngIf="this.disableEndDate">
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="endDate" (change)="getleavetoduration($event)" disabled/>
                            <label class="floating-label-special">To</label>
                            <i class="ri-file-text-line"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" value="{{ this.leaveduration }}" />
                          <label class="floating-label">Duration(in Days)</label>
                          <i class="ri-file-text-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 px-3 py-1">
                    <div class="input">
                      <div class="input-item"> 
                        <input list="taskOwnerDatalist" formControlName="delegatedToUserId"
                          placeholder=" Select Delegation" value="" />
                        <datalist id="taskOwnerDatalist">
                          <option [value]="item" *ngFor="let item of userDropdownList"></option>
                        </datalist>
                        <label class="floating-label">Delegation</label>
                        <i class="ri-file-copy-2-line"></i>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <textarea name="" id="" cols="30" rows="4" formControlName="leaveReason"></textarea>
                          <label class="floating-label">Comment</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 px-3 py-1" *ngIf="!this.leaveStatus || this.leaveStatus === '' || this.leaveStatus === 'Submit' || this.leaveStatus === 'Withdraw'">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="status">
                            <option value="" disabled selected>Select Status</option>
                            <option value="Submit">Submit</option>
                            <option value="Withdraw">Withdraw Leave</option>
                          </select>
                          <label class="floating-label floating-label-special">Select Status</label>
                          <i class="ri-logout-box-r-line"></i>
                        </div>
                        <mat-error *ngIf="f.status.touched && f.status.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.status.errors.required">Status is required</mat-error>
                        </mat-error>
                      </div>
                    </div>

                    <div class="col-sm-4 px-3 py-1" *ngIf="this.leaveStatus === 'Approved' || this.leaveStatus === 'Withdraw After Approval'">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="status">
                            <option value="" disabled selected>Select Status</option>
                            <option value="Approved" disabled>Approved</option>
                            <option value="Withdraw After Approval">Withdraw After Approval</option>
                          </select>
                          <label class="floating-label floating-label-special">Select Status</label>
                          <i class="ri-logout-box-r-line"></i>
                        </div>
                        <mat-error *ngIf="f.status.touched && f.status.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.status.errors.required">Status is required</mat-error>
                        </mat-error>
                      </div>
                    </div>

                    <div class="col-sm-4 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" value="{{ this.compensatoryleave }}" disabled />
                          <label class="floating-label-special">Compensatory Leave</label>
                          <i class="ri-building-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" value="{{ this.earnleave }}" disabled />
                          <label class="floating-label-special">Casual Leave</label>
                          <i class="ri-file-text-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="this.leaveapprowId">
                    <div class="col-sm-12 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <input type="file" (change)="onFileChanged($event)" placeholder="Attachment" />
                          <!-- <label class="floating-label">Attachment</label> -->
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngFor="let item of laattachment">
                    <table style="border: 1px dotted blue; border-radius: 10px">
                      <tbody>
                        <tr>
                          <td>
                            <span>{{ item }}</span>
                          </td>
                          <td>
                            <div class="delete">
                              <button type="button" (click)="
                                downloadleaveApplicationsattachments(item)
                                ">
                                <span><i class="ri-download-cloud-fill"></i></span>
                              </button>
                            </div>
                          </td>
                          <td>
                            <div class="delete">
                              <button type="button" (click)="
                                  deleteleaveApplicationsattachments(item)
                                ">
                                <span><i class="ri-delete-bin-5-line"></i></span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                  <div class="row justify-content-end align-items-center">
                    <div class="col-sm-12 text-end px-3 py-1">
                      <div class="d-flex justify-content-end gap-2">
                        <button class="btn-secondary btn" (click)="backToMyLeave()">Cancel</button>
                        <div *ngIf="!this.leaveapprowId">
                          <button type="button" id="closeCreateModal" class="btn" (click)="addleaveAppRates()"
                            [disabled]="!leaveapplicationForm.valid">
                            <div *ngIf="!this.leaveapprowId">Submit</div>
                          </button>
                        </div>

                        <div *ngIf="this.leaveapprowId">
                          <button type="button" id="closeCreateModal" class="btn" (click)="updateleaveappDetailsbyId()"
                            [disabled]="!leaveapplicationForm.valid">
                            <div *ngIf="this.leaveapprowId">Save</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </section>
  </article>
</main>