import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
 import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { UserService } from 'src/app/_services/user/user.service';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  tenantId: string;
  userDetails: any;
  userCreationForm
  defaultColDef:any;
  deleteConfirmationMsg = 'Are you sure you want to delete';
   rowSelection:any
   dataSource: any;
   public columnDefs: ColDef[];
   modeAction: number = 1; //1 - create // 2 - update
   sampleGridFrameworkComponents: any;
   selectedCheckboxData = [];
   private api: GridApi;  
  private columnApi: ColumnApi;
  selectedRow: any;
  rowData: any;
  isNDAAdmin: boolean;
  isHRAdmin: boolean;
  userCreationStatus: any;
  isStatusApproved: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: LocalStorageService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
  ) { 
    {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };
  }
     this.userCreationForm = this.formBuilder.group({
        id: new FormControl(''),
        requesterId: new FormControl(''),
        requesterName: new FormControl(''),
        requestedDate: new FormControl(''),
        status: new FormControl(''),
        userId: new FormControl(''),
        firstName: new FormControl(''),
        lastName: new FormControl(''),
      });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.userDetails = JSON.parse(this.storageService.get('currentUser'));
    this.getAllUserCreation();
    this.isHRAdmin = this.userDetails.admin || this.userDetails.roles.includes('RoleHrmAdministrator');
    this.isNDAAdmin = this.userDetails.admin || this.userDetails.roles.includes('RoleNDATeam');
  }

  get f() {
    return this.userCreationForm.controls;
  }
 
  onSelectionChanged(event) {
    this.isStatusApproved = false;
    console.log(event)
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
    this.userCreationStatus = this.selectedCheckboxData[0].status;
    if(this.userCreationStatus === 'approved'){
      this.isStatusApproved = true;
    }
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    //console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

   private createColumnDefs() {
    return [
      {
        headerName: 'New User',
        valueGetter: function(params){
          let user = params.data.firstName + "  " + params.data.lastName
            return user;
        },
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Requested Date',
        field: 'requestedDate',
        filter: true,
        editable: false,
        sortable: true
      },
       {
        headerName: "Requester Name",
        field: "requesterName",
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Current Designation',
        field: 'currentDesignation',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Emplyment Contract',
        field: 'permanentOrContractor',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Satus',
        field: 'status',
        filter: true,
        editable: false,
        sortable: true,
      },
    ]
  }

    isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  OnboardingForm(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/onboarding-form' ]);
  }

  openndaonboarding(){
    this.router.navigate([this.tenantId + '/dashboard/hr/onboarding-nda', {id: this.selectedCheckboxData[0].id}]);
  }

  updateOnboardingForm(){
    let selectedNodes = this.api.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    let editId = selectedNodes.map((node) => node.data.id);

    this.router.navigate([ this.tenantId + '/dashboard/hr/onboarding-form', { id: this.selectedCheckboxData[0].id } ]);
  }

  getAllUserCreation(){
    this.userService.getAllUserCreation().subscribe((response: any) => {
      this.rowData = response.result;
    })
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete';
    for (let i in this.selectedCheckboxData) {
      if (+i === 0)
        this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].firstName} ${this.selectedCheckboxData[i].lastName}`;
      else if (this.selectedCheckboxData.length === +i + 1)
        this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].firstName} ${this.selectedCheckboxData[i].lastName}`;
      else
        this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].firstName} ${this.selectedCheckboxData[i].lastName}`;
    }
  }

  deleteleaveAppRates() {
    this.userService.deleteUserCreation(
      this.selectedCheckboxData[0].id
    ).subscribe(() => {

      let userId = this.selectedCheckboxData[0].userId;
      this.userService.deleteUser(userId).subscribe(()=> {
        this.getAllUserCreation();
        this.selectedCheckboxData = [];
        $('#closeModalDeleteLeave').trigger('click');
      })
    });
  }
}
