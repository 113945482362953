import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import 'rxjs/add/operator/filter';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from '../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { DatePipe } from '@angular/common';
import { HolidaycalenderService } from 'src/app/_services/holidaycalender/holidaycalender.service';
import { GlobalsettingService } from 'src/app/_services/globalsetting/globalsetting.service';

@Component({
  selector: 'app-leave-first-page',
  templateUrl: './leave-first-page.component.html',
  styleUrls: ['./leave-first-page.component.scss'],
})
export class LeaveFirstPageComponent implements OnInit {
  leaveapplicationForm;
  leaveUserForm;
  balanceLeaveForm;
  userDropdownList= [];
  rowData: any;
  public columnDefs: ColDef[];
  modeAction: number = 1; //1 - create // 2 - update
  // gridApi and columnApi
  private api: GridApi;
  private columnApi: ColumnApi;
  defaultColDef: any;
  rowSelection: any;
  tenantId: string;
  userDetails: any;
  form: FormGroup;
  selectedRow: any;
  modalService: any;
  currentUser: any;
  jsonData: any;
  emailPattern = '^[a-z0-9._%+-]+\\.[a-z]{2,4}$';
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';
  dataSource_leaveapplication: any;
  editId: number;
  setleaveappeditcounter: number;
  earnleave: number;
  compensatoryleave: number;
  isAdmin: boolean;
  getUserName: any;
  isMaternityLeave: boolean;
  isPaternityLeave: boolean;
  maternityLeave: any;
  paternityLeave: any;
  leaveStartDate: Date;
  holiday: any;
  leaveduration: number;
  isLeaveApplicationDisabled: boolean = false;
  disableLeaveAppDateArray: [] = [];
  disabledDateMsg = 'This function is currently disabled';

  TodayDate: string;
  date: Date;

  constructor(
    private storageService: LocalStorageService,
    private HrService: HrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private allocationService: AllocationService,
    private datePipe: DatePipe,
    private holidaycalenderService: HolidaycalenderService,
    private globalsettingService: GlobalsettingService
  ) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();

    this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent,
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required],
    });

    this.leaveapplicationForm = this.formBuilder.group({
      id: new FormControl(''),
      userId: new FormControl(''),
      leavetype: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      leaveReason: new FormControl(''),
      status: new FormControl(''),
      applicationDate: new FormControl(''),
      appliedByUserId: new FormControl(''),
      appliedByUserName: new FormControl(''),
      userIdUserName: new FormControl(''),
      applicationTitle: new FormControl(''),
      duration: new FormControl(''),
      delegatedToUserId: new FormControl(''),
      delegatedToUserName: new FormControl(''),
      halfDay: new FormControl(''),
    });

    this.leaveUserForm = this.formBuilder.group({
      id: new FormControl(''),
      employeeId: new FormControl(''),
    });

    this.balanceLeaveForm = this.formBuilder.group({
      id: new FormControl(''),
      userId: new FormControl(''),
      leaveType: new FormControl(''),
      balance: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.userDetails = JSON.parse(this.storageService.get('currentUser'));
    this.getAllHolidays();
    setTimeout( () => { /*Your Code*/ }, 2000 );
    this.getUserLeaveApplicationDetails();
    this.getUserLeveBalance(this.userDetails.id);
    this.getUserList();
    this.date = new Date();
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;
    this.getGlobalSettingByName();
    this.isAdmin = this.userDetails.admin || this.userDetails.roles.includes('RoleHrmAdministrator') ;
  }

  getGlobalSettingByName(){
    // this.TodayDate is in format yyyy-MM-dd
    let date : string = this.TodayDate.split('-')[2];
    this.globalsettingService.getGlobalSettingByName('DISABLE_LEAVE_APPLICATION').subscribe((response:any[]) => {
      if(response?.length>0){
        this.disableLeaveAppDateArray = response[0].globalSettingValue?.split(',');
        for(let i=0; i < this.disableLeaveAppDateArray.length; i++){
          if(this.disableLeaveAppDateArray[i] === date){
            this.isLeaveApplicationDisabled = true;
          }
        }
      }
    });
  }

  getAllHolidays() {
    this.holidaycalenderService.getAllHolidays().subscribe((response:any) => {
      this.holiday = response.result;
    });
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  getUserLeaveApplicationDetails() {
    this.HrService.getUserLeaveApplicationDetails(
      this.userDetails.id
    ).subscribe((response: any) => {
      this.rowData = response.result;

      if(response.result.filter((leave) => leave.leavetype === 'Maternity Leave').length > 0){
        if(response.result.filter((leave) => leave.startDate < this.TodayDate) || response.result.filter((leave) => leave.endDate > this.TodayDate) ){
          this.isMaternityLeave = true;
        }
      }

      if(response.result.filter((leave) => leave.leavetype === 'Paternity Leave').length > 0){
        if(response.result.filter((leave) => leave.startDate < this.TodayDate) || response.result.filter((leave) => leave.endDate > this.TodayDate) ){
          this.isPaternityLeave = true;
        }
      }

      for(let i=0; i<response.result.length; i++){
        if(response.result[i].leavetype === 'Maternity Leave'){
          this.leaveStartDate = response.result[i].startDate;
          this.calculateduration(this.leaveStartDate);
        }
        if(response.result[i].leavetype === 'Paternity Leave'){
          this.leaveStartDate = response.result[i].startDate;
          this.calculateduration(this.leaveStartDate);
        }
      }
    });
  }

  getLeaveApplicationByUserId() {
    let employeeId = this.leaveUserForm.get('employeeId').value.split(' . ')[0];
    this.HrService.getLeaveApplicationByUserId(
      employeeId
    ).subscribe((response: any) => {
      this.rowData = response.result;

      this.isMaternityLeave = response.result.filter((leave) => leave.leavetype === 'Maternity Leave').length > 0;
      this.isPaternityLeave = response.result.filter((leave) => leave.leavetype === 'Paternity Leave').length > 0;

      for(let i=0; i<0; i++){
        if(response.result[i].leavetype === 'Maternity Leave'){
          this.leaveStartDate = response.result[i].startDate;
          this.calculateduration(this.leaveStartDate);
        }
        if(response.result[i].leavetype === 'Paternity Leave'){
          this.leaveStartDate = response.result[i].startDate;
          this.calculateduration(this.leaveStartDate);
        }
      }
    });
    this.getUserLeveBalance(employeeId);
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space
  onGridReady(params): void {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  // create column definitions
  private createColumnDefs() {
    return [
      {
        headerName: 'Application Title',
        field: 'applicationTitle',
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Applied For',
        field: 'userIdUserName',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Leave Type',
        valueGetter: function(params){
          if(params.data.leavetype === 'Earned Leave') {
            let leavetype = 'Casual Leave'
            return leavetype;
          } else {
              return params.data.leavetype;
          }
        },
        // field: 'leavetype',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Application Date',
        field: 'applicationDate',
        filter: true,
        editable: false,
        sortable: true,
        //autoSizeColumns: true,
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        filter: true,
        editable: false,
        sortable: true,
        //autoSizeColumns: true,
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        filter: true,
        editable: false,
        sortable: true,
        //autoSizeColumns: true,
      },
      {
        headerName: 'Duration',
        field: 'duration',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Leave Reason',
        field: 'leaveReason',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Status',
        field: 'status',
        //autoSizeColumns: true,
        maxWidth: 150,
        cellStyle: (params) => {
          if (params.value === 'Save' || params.value === 'save') {
            return { color: 'blue', 'font-weight': 'bold' };
          } else if (params.value === 'Submit' || params.value === 'submit') {
            return { color: 'orange', 'font-weight': 'bold' };
          } else if (params.value === 'Approved' || params.value === 'approved') {
            return { color: 'green', 'font-weight': 'bold' };
          } else if (params.value === 'Reject' || params.value === 'reject') {
            return { color: 'red', 'font-weight': 'bold' };
          } else if (
            params.value === 'Withdraw' ||
            params.value === 'withdraw'
          ) {
            return { color: 'grey', 'font-weight': 'bold' };
          }
          return null;
        },
        filter: true,
        editable: false,
        sortable: true,
      },
    ];
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  openLeaveApp() {
    // location.href = "{this.tenantId}/dashboard/hr/leave-application";
    this.router.navigate([this.tenantId + '/dashboard/hr/leave-application']);
  }

  updateLeaveApp() {
    let selectedNodes = this.api.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    let editId = selectedNodes.map((node) => node.data.id);
    this.router.navigate([
      this.tenantId + '/dashboard/hr/leave-application',
      { id: this.selectedCheckboxData[0].id },
    ]);
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }
  }

  private deleteGridRow(params: any) {
    this.HrService.deleteleaveAppRates(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  editOpenModal() {
    this.modeAction = 2;
    this.leaveapplicationForm.patchValue(this.selectedCheckboxData[0]);
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete';
    for (let i in this.selectedCheckboxData) {
      if (+i === 0)
        this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].applicationTitle} ${this.selectedCheckboxData[i].userIdUserName}`;
      else if (this.selectedCheckboxData.length === +i + 1)
        this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].applicationTitle} ${this.selectedCheckboxData[i].userIdUserName}`;
      else
        this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].applicationTitle} ${this.selectedCheckboxData[i].userIdUserName}`;
    }
  }

  deleteleaveAppRates() {
    this.HrService.deleteleaveAppRates(
      this.selectedCheckboxData[0].id
    ).subscribe(() => {
      this.getUserLeaveApplicationDetails();
      this.selectedCheckboxData = [];
      $('#closeModalDeleteLeave').trigger('click');
      this.getUserLeveBalance(this.userDetails.id);
    });
  }

  // to open edit modal
  openEdit(item) {
    this.selectedRow = item;
    this.leaveapplicationForm.patchValue(item);
  }

  // getter for easy access to form fields
  get f() {
    return this.leaveapplicationForm.controls;
  }

  getUserLeveBalance(id){
    this.HrService.getUserLeveBalance(id).subscribe(
      (response) => {
        for (let item of response.result) {
          this.earnleave = response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0].balance : 0;
          this.compensatoryleave = response.result.filter((leave) => leave.leaveType === 'Compensatory leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Compensatory leave')[0].balance : 0;
        }
      }
    );
  }

  calculateduration(startDate) {
    let start = new Date(startDate);
    let end = new Date(this.TodayDate);
    let totalBusinessDays = 0;

    let current = new Date(start);
    current.setDate(current.getDate());
    let day;
    while (current <= end) {
        day = current.getDay();
        let month = current.getMonth() + 1;
        let currentDate = current.getDate().toString().length === 1 ? "0"+current.getDate().toString() : current.getDate().toString();
        let currentMonth = month.toString().length === 1 ? (("0"+month).toString()) : (month.toString());
        let date = current.getFullYear() + '-' + currentMonth + '-' + currentDate;
        if (day >= 1 && day <= 5) {
          if(! (this.holiday.map(p => p.holidayDate).includes(date))){
            ++totalBusinessDays;
          }
        }
        current.setDate(current.getDate() + 1);
      }
    this.leaveduration = totalBusinessDays;

    if(this.isMaternityLeave){
      this.maternityLeave = 90 - this.leaveduration;
    }
    if(this.isPaternityLeave){
      this.paternityLeave = 5 - this.leaveduration;
    }
  }

  updateUserLeveBalance(){
    var data = {
      id: this.balanceLeaveForm.get('id').value,
      userId: parseInt( this.balanceLeaveForm.get('userId').value.toString().substring(0, this.balanceLeaveForm.get('userId').value.toString().indexOf('.')) ),
      leaveType:  this.balanceLeaveForm.get('leaveType').value,
      balance:  this.balanceLeaveForm.get('balance').value,
    }
    this.HrService.updateUserLeveBalance(data['userId'], data['id'], data).subscribe((response:any) => {
      this.getUserLeveBalance(data['id']);
    });
  }

  leaveBook(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaveBook']);
  }
}
