<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div class="canvas-header row justify-content-between align-items-center" style="padding: 0 !important;">
            <nav class="col-sm-8 p-0">
              <div class="nav nav-tabs border-0" id="nav-tab">
                <a class="nav-item nav-link title active p-2"><h2>Timesheet</h2></a>
                <a class="nav-item nav-link title p-2"  (click)="projectTask()" role="tab"><h2>Project Task</h2></a>
              </div>
            </nav>
            
            <div class="col-sm-4 d-flex justify-content-end">
              <div class="col-sm-3 text-end">
                <button type="button" title="Report" class="btn btn-sm" (click)="timesheetReport()" *ngIf="this.isManager">
                  <div>Report</div>
                </button>
              </div>
              <div class="mx-2">
                <button type="button" title="Graph" (click)="timesheetGraph()" class="btn btn-icon">
                  <i class="ri-bar-chart-grouped-line"></i>
                </button>
              </div>
              <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                <button *ngIf="this.isDisableEdit" type="button" title="Edit" (click)="editOpenModal()">
                  <span><i class="ri-pencil-line"></i></span>
                </button>
              </div>
              <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                <button *ngIf="!this.isDisableEdit" type="button" title="Edit" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#timesheet">
                  <span><i class="ri-pencil-line"></i></span>
                </button>
              </div>
              <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                <button *ngIf="!this.isDisableEdit" type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteTimesheetModal" (click)="deleteOpenModal()">
                  <span><i class="ri-delete-bin-5-line"></i></span>
                </button>
              </div>
              <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                <button *ngIf="this.isDisableEdit" type="button" title="Delete" (click)="deleteOpenModal()">
                  <span><i class="ri-delete-bin-5-line"></i></span>
                </button>
              </div>
              <div>
                <button type="button" title="Create" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#timesheet" class="btn btn-icon">
                  <i class="ri-add-line"></i>
                </button>
              </div>

            </div>
          </div>
          <div class="row pt-3">
            <div class="col-sm-12 p-0">
              <div class="canvas-data px-0">
                <form [formGroup]="timesheetForm">
                  <div class="row m-0">
                    <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="date" formControlName="startDate"/>
                          <label class="floating-label-special">From</label>
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="date" formControlName="endDate"/>
                          <label class="floating-label-special">To</label>
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                      <button class="btn" (click)="gettimesheetSummary()">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div> 
          <ag-grid-angular style="height: 620px;" class="ag-theme-material" (gridReady)="onGridReady($event)"
            [rowData]="rowData" [columnDefs]="columnDefs" pagination="true" [defaultColDef]="defaultColDef"
            [suppressRowClickSelection]="true" [rowSelection]="rowSelection"
            (selectionChanged)="onSelectionChanged($event)" paginationPageSize="10">
          </ag-grid-angular>
        </div>
      </section>
    </section>
  </article>
</main>

<!-- Create new Timesheet -->
<div class="modal fade bd-example-modal-lg" id="timesheet" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="timesheetForm">
        <div class="modal-header">
          <h5 class="modal-title">Create Timesheet</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6">  
              <div class="input">
                <div class="input-item">
                  <input list="assignedTo" formControlName="assignedTo" placeholder="" />
                  <datalist id="assignedTo">
                    <option [value]="item" *ngFor="let item of userDropdownList" [disabled] = "modeAction === 2"></option>
                  </datalist>
                  <label class="floating-label">Select Assign To</label>
                  <i class="ri-user-line"></i>
                </div>
                <mat-error *ngIf="f.assignedTo.touched && f.assignedTo.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.assignedTo.errors.required">Assigned to user is required</mat-error>
                </mat-error>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <label class="floating-label"></label>
                  <input list="projectDropdownList" formControlName="projectId" (change)="fetchProjectDetails($event)" />
                  <datalist id="projectDropdownList">
                    <option [value]="item" *ngFor="let item of projectDropdownList" ></option>
                  </datalist>
                  <label class="floating-label">Select Project</label>
                  <i class="ri-shield-user-line"></i>
                </div>
                <mat-error *ngIf="f.projectId.touched && f.projectId.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.projectId.errors.required">Project Name is required</mat-error>
                </mat-error>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <label class="floating-label"></label>
                  <input list="subProjectDropdownList" formControlName="subprojectId"/>
                    <datalist id="subProjectDropdownList">
                      <option [value]="item" *ngFor="let item of subProjectDropdownList"></option>
                    </datalist>
                  <label class="floating-label">Select Sub Project</label>
                  <i class="ri-shield-user-line"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input type="text" disabled formControlName="projectManagerId"/>
                  <label class="floating-label-special">Project Manager</label>
                  <i class="ri-shield-user-line"></i>
                </div>
                <mat-error *ngIf="f.projectManagerId.touched && f.projectManagerId.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.projectManagerId.errors.required">Project Manager is required</mat-error>
                </mat-error>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input formControlName="leadEngineerName" value="{{this.leadEngineer}}" type="text" disabled/>
                  <label for="part" class="floating-label-special">Lead Engineer Name</label>
                  <i class="ri-time-line"></i>
                </div>
              </div>
            </div>
            
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input list="projectTask" formControlName="projectTaskName" (change)="getTaskDetails($event)"/>
                  <datalist id="projectTask">
                    <option [value]="task" *ngFor="let task of projectTaskDropdown"></option>
                  </datalist>
                  <label for="projectTaskName" class="floating-label-special">Select Project Task</label>
                  <i class="ri-time-line"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input formControlName="system" disabled>
                  <label class="floating-label-special">System Information</label>
                  <i class="ri-menu-unfold-line"></i>
                </div>
              </div>
            </div> 
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input formControlName="subSystem" disabled>
                  <label class="floating-label-special">Sub-System Information</label>
                  <i class="ri-menu-unfold-line"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input formControlName="subSubSystem" disabled>
                  <label class="floating-label-special">Sub Sub-System Information</label>
                  <i class="ri-menu-unfold-line"></i>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input formControlName="startDate" (change)="gettsfromduration($event)" type="date" disabled required/>
                  <label for="startDate" class="floating-label">From Date</label>
                  <i class="ri-calendar-check-line"></i>
                </div>
                <mat-error *ngIf="f.startDate.touched && f.startDate.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                </mat-error>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input formControlName="endDate" (change)="gettsfromduration($event)" type="date" disabled/>
                  <label for="endDate" class="floating-label">To Date</label>
                  <i class="ri-calendar-check-line"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <input formControlName="totalHours" type="number" min="0"/>
                  <label for="totalHours" class="floating-label">Punch Hours</label>
                  <i class="ri-time-line"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input">
                <div class="input-item">
                  <label
                class="floating-label floating-label-special"
              >Select status</label>
              <select formControlName="status">
                <option value="" disabled selected>Select status</option>
                <option value="Save">Save</option>
                <option value="Submit">Submit</option>
              </select>
              <i class="ri-24-hours-line"></i>
                </div>
              </div>    
            </div>
            
            <div class="col-sm-12">
              <div class="input">
                <div class="input-item">
                  <textarea name="" id="" cols="12" rows="2" formControlName="description"></textarea>
                  <label class="floating-label-special">Description</label>
                  <i class="ri-file-list-line"></i>
                </div>
              </div> 
            </div>
          </div>
          
        </div>
        <div class="modal-footer">
          <button type="button" id="closeModalTimeSheet" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createTimesheet()" [disabled]="!timesheetForm.valid">Create</button>
          <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updateTimesheeets()" [disabled]="!timesheetForm.valid">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>  

<!-- Delete an item -->
<div class="modal fade" id="deleteTimesheetModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form action="">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure want to delete?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>{{ deleteConfirmationMsg }}?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button id="closeModalDeleteTimesheet" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteTimesheetList()">Yes Delete</button>
        </div>
      </form>
    </div>
  </div>
</div>

