import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HolidaycalenderService } from '../../../../_services/holidaycalender/holidaycalender.service';

@Component({
  selector: 'app-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.scss']
})
export class HolidayListComponent implements OnInit {
  holidayForm;
  selectedRow: any;
  jsonData: any; 
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
  tenantId: string;
  currentUser: any;
  selectedFile: File;
  form: FormGroup;
  sampleGridFrameworkComponents: any;
  isAdmin : boolean;
  gridLength = 0;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete';
  year = new Date().getFullYear();


  constructor(
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private holidaycalenderService: HolidaycalenderService
  ) { 
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
  this.columnDefs = this.createColumnDefs();  

  this.sampleGridFrameworkComponents = {
    actionRowRendererComponent: ActionItemsGridRowRendererComponent
  };

    this.holidayForm = new FormGroup({
      name: new FormControl(''), 
      holidayDate: new FormControl(''),
    })
  }


  // getter for easy access to form fields
  get f() {
    return this.holidayForm.controls;
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isAdmin = this.currentUser.admin ||this.currentUser.roles.includes('RoleHrmAdministrator');
    this.getAllHolidaysByYear();
    console.log(this.year);
  }

  getAllHolidaysByYear() {
    this.holidaycalenderService.getAllHolidaysByYear(this.year).subscribe((response:any) => {
      this.rowData = response.result;
    });
  }

  addHoliday() {
    var addcclrdata = {
      name: this.holidayForm.get('name').value,
      holidayDate: this.holidayForm.get('holidayDate').value,
    }
    this.holidaycalenderService.createHolidays(addcclrdata).subscribe((response: any) => {
      console.log("holiday ADD Response", response);
      this.snackBar.open("Holiday Added!", 'Close', { duration: 2000 });
      this.getAllHolidaysByYear();
      $('#closeCreateModal').trigger("click");
    });
  }

  editOpenModal() {
    this.selectedRow = this.selectedCheckboxData[0];
    this.modeAction = 2;
    this.holidayForm.patchValue(this.selectedCheckboxData[0]);
  }

  editHoliday() {
    let data = {
      name: this.holidayForm.get('name').value,
      holidayDate: this.holidayForm.get('holidayDate').value,
    }
    this.holidaycalenderService.updateHolidays(data , this.selectedRow.id).subscribe((response: any) => {
      this.getAllHolidaysByYear();
      $('#closeCreateModal').trigger("click");
      this.modeAction = 1;
    });
  }

  

  addOpenModal() {
    this.modeAction = 1;
    this.resetForm();
  }

  resetForm() {
    this.holidayForm.reset();
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }  

    // create column definitions  
    private createColumnDefs() {
      return [
      {
        headerName: 'Name',
        field: 'name',
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Date',
        field: 'holidayDate',
        filter: true,
        editable: false,
        sortable: true
      },
     ]
    }

    onSelectionChanged(event) {
      this.selectedCheckboxData = []
      let select = event.api.getSelectedNodes();
      for (let item of select) {
        this.selectedCheckboxData.push(item.data)
      }
    }

    isFirstColumn(params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns();
      const thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    }

    createOpenModal() {
      this.modeAction = 1;
      this.holidayForm.reset();
      this.holidayForm.patchValue({
        name:  '',
        holidayDate:  '',
        
      });
    }

    performGridActionItem(params) {
      if (params.event.currentTarget.value == 'delete') {
        this.deleteGridRow(params);
      }     
    }
  
    private deleteGridRow(params: any) {
      this.holidaycalenderService.deleteHolidays(params.params.data.id).subscribe(() => {
        var selectedData = params.params.data;
        this.api.applyTransaction({ remove: [selectedData] });
      });
    }
  
    deleteOpenModal() {
      this.deleteConfirmationMsg = 'Are you sure you want to delete'
      for (let i in this.selectedCheckboxData) {
        if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].name}`;
        else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].name}`;
        else this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].name}`;
      }
    }
  
    deleteHoliday() {
      this.holidaycalenderService.deleteHolidays(this.selectedCheckboxData[0].id).subscribe((response: any) => {
        this.getAllHolidaysByYear();
        this.selectedCheckboxData = []
        $('#closeDeleteModal').trigger("click");
      });
    }
   

}
