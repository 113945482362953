import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { Router } from '@angular/router';
import { TaxctcService } from '../../../../_services/taxctc/taxctc.service';
import { data } from 'jquery';

@Component({
  selector: 'app-tax-ctc',
  templateUrl: './tax-ctc.component.html',
  styleUrls: ['./tax-ctc.component.scss']
})
export class TaxCtcComponent implements OnInit {
  jsonData: any;
  // row data and column definitions 
  taxCtcform 
  rowData: any;
  form: FormGroup;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  tenantId: string;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete';
  selectedFinancialYear: any;
  userCTCArray : any[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: NgxPermissionsService, 
    private storageService: LocalStorageService, 
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private router: Router,
    private taxctcService :TaxctcService
  ) { 
    {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };
  }
  this.taxCtcform = this.formBuilder.group({
    employeeId: new FormControl(''),
    financialYear: new FormControl(''),
    ctc: new FormControl(''),
    startMonth: new FormControl(''),
    endMonth: new FormControl(''),
    componentName: new FormControl(''),
    componentValue: new FormControl(''),
    miscellaneous: new FormControl(''),
    deduction: new FormControl(''),
    taxRegimeNo: new FormControl(''),
  })
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getUserCtcs()
  }

  getUserCtcs(){
    if(this.selectedFinancialYear){
      this.taxctcService.getAlluserCtcs().subscribe((response: any) => {
        this.userCTCArray = [];
        for(let item of response.result){
          if(item.financialYear === this.selectedFinancialYear){
            this.userCTCArray.push(item);
          }
        }
        this.rowData = this.userCTCArray;
      });
    }
    else{
      this.taxctcService.getAlluserCtcs().subscribe((response: any) => {
        this.rowData = response.result; 
        console.log("this.rowData:", this.rowData);
      });
    }
  }

  handleChange(fYear){
    this.selectedFinancialYear = fYear.target.value;
    this.getUserCtcs();
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params: any) {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Financial Year",
        field: "financialYear",
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Tax RegimeNo',
        field: 'taxRegimeNo',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Emp Id',
        field: 'localId',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Name',
        field: 'employeeName',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'CTC',
        field: 'ctc',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Start Month',
        field: 'startMonth',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'End Month',
        field: 'endMonth',
        filter: true,
        editable: false,
        sortable: true
      },
     
    ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  openTaxForm() {
    // location.href = "{this.tenantId}/dashboard/hr/leave-application";
    this.router.navigate([this.tenantId + '/dashboard/finance/tax-ctc-form']);
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }
  }

  private deleteGridRow(params: any) {
    this.taxctcService.deleteuserCtcs(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  editOpenModal() {
    this.modeAction = 2;
    this.taxCtcform.patchValue(this.selectedCheckboxData[0])
  }

  updateTaxctcApp() {
    this.router.navigate([
      this.tenantId + '/dashboard/finance/tax-ctc-form', { id: this.selectedCheckboxData[0].id }]);
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].componentName} `;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].componentName} `;
      else this.deleteConfirmationMsg += `, ${this.selectedCheckboxData[i].componentName} `;
    }
  }


  deleteTaxMetadata() {
    this.taxctcService.deleteuserCtcs(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getUserCtcs();
      this.selectedCheckboxData = []
      $('#closeModalDeleteTaxMetaData').click()
    });
  }

}
